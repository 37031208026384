import React, { useEffect, useMemo, useState } from "react";
import BoxWrapper from "../../../components/BoxWrapper";
import { useQuery } from "react-query";
import apiService from "../../../service/apiService";
import { useParams, useSearchParams } from "react-router-dom";
import { BOOKING_STATUS, PAYMENT_METHODS } from "../../../config/constants";
import { useCookie } from "../../../hooks/useCookie";
import SearchForm from "../../../components/inputs/SearchForm";
import RenderTable from "../../../components/RenderTable";
import { BookingSvg } from "../../../components/SideBarMenu";
import { general } from "../../../locales/general";
import Pagination from "../../../components/Pagination";
import { CFormCheck, CFormSelect } from "@coreui/react";
import { useDebounce } from "../../../hooks/useDebounce";
import { toQuery } from "../../../helpers/general";
import EventReservationTable from "./EventReservationTable";

const PAGE_SIZE = 10;

export default function EventReservation() {
  const [bookingStatus, setBookingStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [onlyGuest, setOnlyGuest] = useState("false");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const params = useParams();
  const { token } = useCookie("vToken");
  const search = useDebounce(searchQuery, 2000);
  const [searchParams] = useSearchParams();

  const sort = searchParams.get("sort") ?? "createdAt:desc";

  useEffect(() => {
    setCurrentPage(1);
  }, [bookingStatus, paymentMethod, onlyGuest, searchQuery]);

  const { data: bookings, isLoading } = useQuery(
    [
      "getBookedEvents",
      bookingStatus,
      paymentMethod,
      onlyGuest,
      token,
      currentPage,
      search,
      sort
    ],
    () => {
      return apiService.MakeGetRequest(
        `events/${params?.eventId}/bookings${toQuery({
          page: currentPage,
          limit: PAGE_SIZE,
          status: bookingStatus,
          "payment-method": paymentMethod,
          "only-guest": onlyGuest,
          q: search,
          sort
        })}`,
        token
      );
    }
  );

  return (
    <div>
      <BoxWrapper>
        <div className="d-flex justify-content-between align-items-center flex-md-row flex-column-reverse">
          <div className="col-12 col-md-7 d-flex justify-content-end">
            <SearchForm onChange={setSearchQuery} value={searchQuery} />
          </div>
          <div
            className="d-flex col-12 col-md-3 align-items-center justify-content-center gap-3 bg-gradient-primary p-3 "
            style={{ borderTopRightRadius: "8px", alignSelf: "flex-start" }}
          >
            <BookingSvg className="fill-white" />
            <p className="fs-5 fw-bold m-0 text-white">
              {bookings?.meta?.totalItems}{" "}
              {bookings?.meta?.totalItems > 1 ? "Réservations" : "Réservation"}
            </p>
          </div>
          {/* </div> */}
        </div>

        <nav className="d-flex px-3 border-bottom mb-4 flex-md-row flex-column">
          <div className="w-100 p-3">
            <label htmlFor="">Statut de réservation:</label>
            <CFormSelect
              name="booking_status"
              id="booking_status"
              className="custom-input"
              value={bookingStatus}
              required
              onChange={e => setBookingStatus(e.target.value)}
            >
              <option value="">Toutes</option>
              <option value={BOOKING_STATUS.WAITING}>En attente</option>
              <option value={BOOKING_STATUS.CONFIRMED}>Approuvé</option>
              <option value={BOOKING_STATUS.PASSED}>Scanné</option>
              <option value={BOOKING_STATUS.CANCELED}>Annulé</option>
            </CFormSelect>
          </div>

          <div className="w-100 mt-4 p-3 d-flex gap-3 justify-content-start justify-content-md-center  align-items-center">
            <CFormCheck
              id="showOnlyGuest"
              label="Afficher uniquement GuestList"
              checked={onlyGuest === "true"}
              onChange={e => setOnlyGuest(e.target.checked ? "true" : "false")}
            />
          </div>

          <div className="w-100 p-3">
            <label htmlFor="">Méthode de paiement:</label>
            <CFormSelect
              name="payment_method"
              id="payment_method"
              className="custom-input"
              required
              value={paymentMethod}
              onChange={e => setPaymentMethod(e.target.value)}
            >
              <option value="">Toutes</option>
              <option value={PAYMENT_METHODS.CARTE_BANCAIRE}>
                Carte Bancaire
              </option>
              <option value={PAYMENT_METHODS.WAFACASH}>Wafacash</option>
              <option value={PAYMENT_METHODS.SUR_PLACE}>Sur Place</option>
              <option value={PAYMENT_METHODS.STRIPE}>Stripe</option>
            </CFormSelect>
          </div>
          <div className="w-50 mt-4 p-3 d-flex gap-3 justify-content-start justify-content-md-center align-items-center">
            <button
              className="btn btn-primary shadow-secondary rounded-pill text-white"
              onClick={() => {
                setBookingStatus("");
                setPaymentMethod("");
                setOnlyGuest("false");
                setSearchQuery("");
              }}
            >
              Initialiser
            </button>
          </div>
        </nav>

        <RenderTable
          loading={isLoading}
          render={() =>
            bookings?.items?.length > 0 ? (
              <EventReservationTable bookings={bookings?.items ?? []} />
            ) : (
              <p className="text-center fs-5 my-2">{general.fr.noData}</p>
            )
          }
        />
      </BoxWrapper>
      <Pagination
        currentPage={currentPage}
        onPageChange={page => {
          setCurrentPage(page);
        }}
        siblingCount={1}
        totalCount={bookings?.meta?.totalPages}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
}
