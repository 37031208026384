import React, { useState, useRef } from "react";

import { useParams } from "react-router-dom";

import { CForm, CFormCheck, CFormInput, CSpinner } from "@coreui/react";

import { useMutation, useQuery, useQueryClient } from "react-query";

import { useToast } from "../../../hooks/useToast";
import { useCookie } from "../../../hooks/useCookie";
import apiService from "../../../service/apiService";

import { general } from "../../../locales/general";
import BoxWrapper from "../../../components/BoxWrapper";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { colors } from "../../../config/theme";

import {
  CButton,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle
} from "@coreui/react";
import { error } from "console";

export default function PanoramaPictures() {
  const params = useParams();
  const { token } = useCookie("vToken");
  const { toastError, toastSuccess } = useToast();
  const queryClient = useQueryClient();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedPictures, setSelectedPictures] = useState<string[]>([]);
  const imageUploadInputRef = useRef<HTMLInputElement>(null);

  const openModal = (image: string) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage("");
    setModalIsOpen(false);
  };

  const { data } = useQuery<Place>(["get360-pictures"], () => {
    return apiService.MakeGetRequest(`places/${params.placeId}`, token);
  });

  const deletePicture = useMutation(
    (id: string) => {
      const fileName = id.split("/").pop();
      return apiService.MakeDeleteRequest(
        `places/${params.placeId}/360-pictures/${fileName}`,
        token
      );
    },
    {
      onError() {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Photo supprimée");
        queryClient.invalidateQueries("get360-pictures");
      }
    }
  );

  const handleBulkDelete = async () => {
    await Promise.all(
      selectedPictures.map(id => {
        return deletePicture.mutate(id);
      })
    );

    setSelectedPictures([]);
  };

  const [images, setImages] = useState<File[]>([]);

  const { isLoading: addingPhoto, mutateAsync } = useMutation(
    (formData: FormData) => {
      return apiService.MakePutRequest(
        `places/${params.placeId}/360-pictures/add`,
        formData,
        token,
        true
      );
    },
    {
      onSuccess: () => {
        toastSuccess(general.fr.message.pictureUploaded);
        queryClient.invalidateQueries("get360-pictures");
      }
    }
  );

  //   const { isLoading: addingPhoto, mutateAsync } = useMutation(
  //     (formData: FormData) => {
  //       console.log("FormData contents:", Array.from(formData.entries()));

  //       return apiService.MakePutRequest(
  //         `places/${params.placeId}/360-pictures/add`,
  //         formData,
  //         token,
  //         true
  //       );
  //     },
  //     {
  //       onSuccess: () => {
  //         toastSuccess(general.fr.message.pictureUploaded);
  //         queryClient.invalidateQueries("get360-pictures");
  //       },
  //       onError: () => {
  //         // handle error
  //         // console.error("upload error:", error);
  //       }
  //     }
  //   );

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = Array.from(e.target.files as FileList);

    //
    // const validFiles = fileList.filter(file => file.type.startsWith("image/"));

    //
    //   setImages(fileList);
    setImages(fileList);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      for (const image of images) {
        const formData = new FormData();
        formData.append("panoramaPicture", image);
        await mutateAsync(formData);
      }
      if (imageUploadInputRef.current) {
        imageUploadInputRef.current.value = "";
      }
      setImages([]);
    } catch (error) {
      toastError(general.fr.message.operationFailed);
    }
  };

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   try {
  //     for (const image of images) {
  //       const formData = new FormData();
  //       // formData.append("picture", image);
  //       formData.append("image", image);
  //       console.log("Sending formData:", Array.from(formData.entries()));

  //       await mutateAsync(formData);
  //     }
  //     if (imageUploadInputRef.current) {
  //       imageUploadInputRef.current.value = "";
  //     }
  //     setImages([]);
  //   } catch (error) {
  //     toastError(general.fr.message.operationFailed);
  //   }
  // };

  const handleSelect = (id: string) => {
    setSelectedPictures(prevSelectedPictures => {
      if (prevSelectedPictures.includes(id)) {
        return prevSelectedPictures.filter(pic => pic !== id);
      } else {
        return [...prevSelectedPictures, id];
      }
    });
  };

  return (
    <div>
      <section>
        <h5 className="fs-4 fw-bold mb-2">Panorama Pictures (360 Photos)</h5>
        <BoxWrapper>
          <div>
            <div className="d-flex justify-content-center align-items-center flex-wrap pt-4">
              <button
                disabled={
                  deletePicture.isLoading || selectedPictures?.length === 0
                }
                onClick={handleBulkDelete}
                className="outline-none  btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center shadow-primary"
              >
                {!deletePicture.isLoading ? (
                  <>
                    <DeleteIcon fill={colors.primary} /> Supprimer des photos
                  </>
                ) : (
                  <>
                    <CSpinner size="sm" /> {general.fr.wait}
                  </>
                )}
              </button>

              <form
                onSubmit={handleSubmit}
                className="d-flex gap-2 p-4 justify-content-center flex-wrap"
              >
                <CFormInput
                  type="file"
                  multiple
                  onChange={handleImageChange}
                  name="panoramaPicture"
                  className="custom-input w-fit mt-0"
                  ref={imageUploadInputRef}
                />
                <button
                  type="submit"
                  className=" btn btn-success green-shadow w-20 px-4 text-white"
                  disabled={addingPhoto || !images.length}
                >
                  {addingPhoto ? <CSpinner size="sm" /> : " Upload"}
                </button>
              </form>
            </div>

            <div className="gallery-container">
              {data?.panoramaPictures?.map((imageUrl, index) => (
                <div key={index} className="gallery-item">
                  <img
                    src={imageUrl}
                    className="img-fluid"
                    alt={`360-picture-${index}`}
                    style={{ objectFit: "cover", height: "100%" }}
                    onClick={() => openModal(imageUrl)}
                  />
                  <div className="checkbox-pic">
                    <CFormCheck
                      type="checkbox"
                      checked={selectedPictures.includes(imageUrl)}
                      onChange={() => handleSelect(imageUrl)}
                    />
                  </div>

                  <button
                    className="delete-button"
                    onClick={() => deletePicture.mutate(imageUrl)}
                  >
                    <DeleteIcon
                      width="20"
                      height="20"
                      fill={colors.primary}
                      className="delete-btn "
                    />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <CModal
            className="popup-modal"
            visible={modalIsOpen}
            onClose={closeModal}
          >
            <CModalHeader closeButton>
              <CModalTitle>Gallery Item</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <img src={selectedImage} className="img-fluid popup-image" />
            </CModalBody>
            <CButton color="secondary" onClick={closeModal}>
              Close
            </CButton>
          </CModal>
        </BoxWrapper>
      </section>
    </div>
  );
}
