import { CForm, CFormInput, CFormSelect, CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useToast } from "../../../../hooks/useToast";
import { useCookie } from "../../../../hooks/useCookie";
import apiService from "../../../../service/apiService";
import { general } from "../../../../locales/general";
import BoxWrapper from "../../../../components/BoxWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormMode } from "../../../../helpers/enums";
import { useForm } from "react-hook-form";
import { verifyFileList } from "../../../../helpers/general";

interface ServiceType {
  available: number;
  label: string;
  picture: File;
  startingPrice: number;
  otherInfo: string | null;
}

const schema = yup.object({
  label: yup.string().required("Ce champ est requis"),
  startingPrice: yup
    .number()
    .nullable()
    .typeError("")
    .transform((_, val) => {
      const result = val == Number(val) ? Number(val) : null;
      return result;
    }),
  startingPriceEuro: yup
    .number()
    .nullable()
    .typeError("")
    .transform((_, val) => {
      const result = val == Number(val) ? Number(val) : null;
      return result;
    }),
  available: yup
    .number()
    .typeError("")
    .default(0)
    .required("Ce champ est requis"),
  otherInfo: yup.string().nullable(),
  picture: yup.mixed().nullable(),
  disabled: yup.string().required("Ce champ est obligatoire"),
  order: yup
    .number()
    // @ts-ignore
    .nullable(true)
    .typeError("")
    .transform((_, val) => {
      if (val == "") return null;
      const result = val == Number(val) ? Number(val) : null;
      return result;
    })
});

export default function EventNewService({ mode = FormMode.CREATE }) {
  const { toastSuccess, toastError } = useToast();
  const [formLoading, setFormLoading] = useState(false);
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue: setFormValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  // @desc create new user
  const createNewServiceMutation = useMutation(async (data: ServiceType) => {
    return await apiService.MakePostRequest(
      "events/service",
      data,
      token,
      true
    );
  });

  const updateServiceMutation = useMutation((data: Partial<ServiceType>) => {
    return apiService.MakePutRequest(
      `services/${params.serviceId}`,
      data,
      token,
      true
    );
  });

  const createService = async (data: any) => {
    try {
      data.eventId = params.eventId;
      setFormLoading(true);
      await createNewServiceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.serviceAdded);
      navigate(`/events/${params.eventId}/services`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updateService = async (data: any) => {
    try {
      setFormLoading(true);
      await updateServiceMutation.mutateAsync(data);
      toastSuccess(general.fr.message.serviceUpdated);
      navigate(`/events/${params.eventId}/services`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async (dataArg: any) => {
    const data = { ...dataArg };
    try {
      data.placeId = params.placeId;
      data.subCategoryId = data.subCategoryId?.id;
      const imageFile = verifyFileList(data.picture);
      data.picture = imageFile;

      if (mode === FormMode.UPDATE) {
        updateService(data);
      } else {
        createService(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.serviceId) {
      const formFields = Object.keys(schema.fields);
      apiService
        .MakeGetRequest(`services/${params.serviceId}`, token)
        .then(response => {
          setFormValue("picture", response.image);
          Object.entries(response).forEach(entry => {
            if (formFields.indexOf(entry[0]) !== -1) {
              // @ts-ignore
              setFormValue(entry[0], entry[1]);
            }
          });
        });
    }
  }, []);

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>
                {mode === FormMode.UPDATE
                  ? "Mise à jour le service"
                  : "Ajouter un nouveau service"}
              </h4>
            </div>
            <CForm
              validated={true}
              className="row form mt-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-md-6 mt-4">
                <label htmlFor="label" className="d-block">
                  Nom du service <span className="text-sm text-red">*</span>
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Nom du service"
                  id="label"
                  required
                  {...register("label")}
                />
                {errors.label?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.label.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="otherInfo" className="d-block">
                  Autres informations
                </label>
                <input
                  type="text"
                  className="custom-input"
                  placeholder="Service autres informations"
                  id="otherInfo"
                  {...register("otherInfo")}
                />
                {errors.otherInfo?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.otherInfo.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="startingPrice" className="d-block">
                  Prix de base <span className="text-sm text-red">*</span>
                </label>
                <input
                  type="number"
                  className="custom-input"
                  placeholder="Prix de base"
                  id="startingPrice"
                  required
                  {...register("startingPrice")}
                  step="0.01"
                />
                {errors.startingPrice?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.startingPrice.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="startingPriceEuro" className="d-block">
                  Prix de base (Euro)
                </label>
                <input
                  type="number"
                  className="custom-input"
                  placeholder="Prix de base"
                  id="startingPriceEuro"
                  {...register("startingPriceEuro")}
                  step="0.01"
                />
                {errors.startingPriceEuro?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.startingPriceEuro.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="available" className="d-block">
                  Espace disponible <span className="text-sm text-red">*</span>
                </label>
                <input
                  type="number"
                  className="custom-input"
                  placeholder="Espace disponible"
                  id="available"
                  required
                  {...register("available")}
                />
                {errors.available?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.available.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="picture" className="d-block">
                  Image
                </label>
                <CFormInput
                  type="file"
                  id="picture"
                  className="my-3"
                  {...register("picture")}
                />
                {errors.picture?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.picture.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="disabled" className="d-block">
                  Désactivé <span className="text-sm text-red">*</span>
                </label>
                <CFormSelect
                  className="my-3"
                  id="disabled"
                  {...register("disabled")}
                >
                  <option selected value="">
                    Choisissez une option
                  </option>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </CFormSelect>
                {errors.disabled?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.disabled.message}
                  </div>
                )}
              </div>

              <div className="col-md-6 mt-4">
                <label htmlFor="order" className="d-block">
                  Ordre
                </label>
                <input
                  type="number"
                  className="custom-input"
                  id="order"
                  {...register("order")}
                />
                {errors.order?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.order.message}
                  </div>
                )}
              </div>

              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to={`/events/${params?.eventId}/services`}
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </CForm>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
