import React, { useEffect, useMemo, useRef, useState } from "react";
import ClockIcon from "../icons/ClockIcon";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import useOnClickOutside from "../../hooks/useOutSideClick";

interface Props {
  selectedTime: string;
  label: string;
  onChange: (val: string) => void;
  numOfHours?: number;
}
export default function TimeSelectInput({
  selectedTime,
  label,
  onChange,
  numOfHours = 24,
}: Props) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="w-100">
      <p className="fw-bold text-sm mb-2">{label}</p>
      <button
        type="button"
        onClick={() => setExpanded((prev) => !prev)}
        className="w-100 position-relative d-flex align-items-center justify-content-between gap-2 border-0 outline-none rounded-sm py-2 px-2 text-sm bg-primary-ligh relative"
      >
        <div className="d-flex align-items-center gap-2">
          <ClockIcon />
          <span className="">
            {selectedTime?.length ? selectedTime : "Choisissez une heure"}
          </span>
        </div>
        {!expanded ? (
          <ChevronDownIcon className="w-5 h-5" />
        ) : (
          <ChevronUpIcon className="w-5 h-5" />
        )}
      </button>
      {expanded && (
        <TimeSelect
          onHide={() => setExpanded(false)}
          onChange={onChange}
          selectedTime={selectedTime}
          numOfHours={numOfHours}
        />
      )}
    </div>
  );
}

interface TimeSelectProps {
  onHide: () => void;
  onChange: (value: string) => void;
  selectedTime: string;
  minHr?: number;
  numOfHours?: number;
}
function TimeSelect({
  onHide,
  onChange,
  selectedTime,
  minHr = 0,
  numOfHours = 24,
}: TimeSelectProps) {
  const closeTimeRef = useRef(null);
  useOnClickOutside(closeTimeRef, onHide);

  const [selectedTimes, setSelectedTimes] = useState<{
    [index: string]: string | null;
  }>({
    hr: null,
    min: null
  });

  useEffect(() => {
    const [hr, min = "00"] = selectedTime?.split(":") ?? ["00", "00"];
    setSelectedTimes({ hr, min });
  }, []);

  useEffect(() => {
    if (selectedTimes.hr && selectedTimes.min) {
      onChange(`${selectedTimes.hr}:${selectedTimes.min}`);
    }
  }, [selectedTimes]);

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onHide();
  };

  const minutes = useMemo(() => {
    return new Array(60).fill(0).map((c, i) => (
      <button
        type="button"
        key={i}
        onClick={() =>
          setSelectedTimes(time => ({
            ...time,
            min: i >= 10 ? i.toString() : `0${i}`
          }))
        }
        className={`border-0 outline-none d-block w-full mx-auto mb-2 d-flex align-items-center justify-content-center text-center rounded-sm ${
          selectedTimes?.min && +selectedTimes.min === i
            ? "bg-primary-shade"
            : "bg-transparent"
        }`}
      >
        {i >= 10 ? i : `0${i}`}
      </button>
    ));
  }, [selectedTimes.min]);

  const hours = useMemo(() => {
    return new Array(numOfHours).fill(0).map((c, i) => (
      <button
        type="button"
        key={i}
        onClick={() =>
          setSelectedTimes(time => ({
            ...time,
            hr: i >= 10 ? i.toString() : `0${i}`
          }))
        }
        className={`border-0 outline-none d-block w-full mx-auto mb-2 d-flex align-items-center justify-content-center text-center rounded-sm ${
          selectedTimes?.hr && +selectedTimes.hr === i
            ? "bg-primary-shade"
            : "bg-transparent"
        }`}
        disabled={i < minHr}
      >
        {i >= 10 ? i : `0${i}`}
      </button>
    ));
  }, [minHr, selectedTimes.hr, numOfHours]);

  return (
    <div className="daytime-wrapper pb-2 absolute" ref={closeTimeRef}>
      <div className="row py-2">
        <div className="col ms-2 d-flex flex-column align-items-center text-center overflow-y-scroll">
          {hours}
        </div>
        <div className="col overflow-y-scroll">{minutes}</div>
      </div>
      <div className="d-flex align-items-center gap-3 justify-content-center">
        <button
          onClick={onClose}
          type="button"
          className="outline-none border-0 bg-transparent text-sm"
        >
          Annuler
        </button>
        <button
          onClick={onClose}
          type="button"
          className="outline-none border text-danger rounded-full py-1 px-2 bg-transparent text-sm"
        >
          Enregistrer
        </button>
      </div>
    </div>
  );
}
