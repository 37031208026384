// @ts-nocheck
import { CFormInput, CSpinner } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import BoxWrapper from "../../../components/BoxWrapper";
import RichTextEditor from "../../../components/RichTextEditor";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import apiService from "../../../service/apiService";
import { useAuth } from "../../../hooks/useAuth";
import * as yup from "yup";
import { verifyFileList } from "../../../helpers/general";
import { FormSelectAPI } from "../../../components/FormReactSelect";
import { useAuthContext } from "../../../context/auth";
import { FormMode } from "../../../helpers/enums";
import ImagePreview from "../../../components/ImagePreview";
import UndoIcon from "../../../components/icons/UndoIcon";
import FileUploader from "../../../components/FileUploader";
import InfiniteScrollSelect from "../../../components/InfiniteScrollSelect";

const packItemSchema = {
  title: yup.string().required("Ce champ est obligatoire"),
  isSaved: yup.boolean().default(false),
  isDeleted: yup.boolean().default(false)
};

const isPackItemsEmpty = items => {
  return items.filter(items => items.isDeleted === false).length <= 0;
};

export default function NewPack({ mode = FormMode.CREATE }) {
  const [formLoading, setFormLoading] = useState(false);
  const [description, setDescription] = useState("");
  const { toastSuccess, toastError } = useToast();
  const { user } = useAuthContext();
  const { token } = useCookie("vToken");
  const navigate = useNavigate();
  const params = useParams();

  const { isAdmin } = useAuth();

  const schema = yup.object({
    title: yup.string().required("Ce champ est obligatoire"),
    image: yup.mixed().required("Ce champ est obligatoire"),
    shortDescription: yup.string().nullable(),
    description: yup.string().nullable(),
    price: yup
      .number()
      .positive()
      .required("Ce champ est obligatoire")
      .typeError("S'il vous plait, entrez un nombre valide")
      .transform(v => (isNaN(v) ? undefined : v)),
    priceInEuro: yup
      .number()
      .positive()
      .nullable(true)
      .typeError()
      .transform((_, val) => (val ? Number(val) : null)),
    ...(isAdmin
      ? {
          placeId: yup
            .object()
            .required("Ce champ est obligatoire")
            .typeError("Ce champ est obligatoire")
        }
      : {}),
    items: yup
      .array()
      .min(1, "Veuillez saisir au moins un élément")
      .of(yup.object().shape(packItemSchema))
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control: formControl,
    setValue: setFormValue,
    getValues: getFormValue,
    watch: watchFormValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      items: [{ title: "", isSaved: false, isDeleted: false }]
    }
  });

  const selectedPlace = watchFormValue("placeId");
  const [image, setImage] = useState(1);
  const {
    fields: packItemsFields,
    append: appendPackItemField,
    remove: removePackItemField,
    update: updatePackItemField
  } = useFieldArray({
    control: formControl,
    name: "items"
  });

  const formPackItemsValue = watchFormValue("items");

  const createNewPackMutation = useMutation(async data => {
    return await apiService.MakePostRequest("packs", data, token, true);
  });

  const updatePackMutation = useMutation(async data => {
    return await apiService.MakePutRequest(
      `packs/${params.id}`,
      data,
      token,
      true
    );
  });

  const updatePackItemMutation = useMutation(async ({ packItemId, data }) => {
    return await apiService.MakePutRequest(
      `packs/${params.id}/pack-item/${packItemId}`,
      data,
      token
    );
  });

  const createNewPackItemMutation = useMutation(async ({ packId, data }) => {
    return await apiService.MakePostRequest(
      `packs/${packId}/pack-item`,
      data,
      token
    );
  });

  const deletePackItemMutation = useMutation(async packItemId => {
    return await apiService.MakeDeleteRequest(
      `packs/${params.id}/pack-item/${packItemId}`,
      token
    );
  });

  const createPack = async data => {
    try {
      setFormLoading(true);
      const newPack = await createNewPackMutation.mutateAsync(data);
      const packItems = data.items.map(async (item, index) => {
        return await createNewPackItemMutation.mutateAsync({
          packId: newPack.id,
          data: item
        });
      });
      await Promise.all(packItems);
      toastSuccess(general.fr.message.packCreated);
      navigate(`/packs`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const updatePack = async data => {
    try {
      setFormLoading(true);
      const updatedPack = await updatePackMutation.mutateAsync(data);
      const packItemsToDelete = data.items.filter(
        packItem => packItem.isDeleted === true && packItem.isSaved === true
      );
      const packItemsToUpdate = data.items.filter(
        packItem => packItem.isSaved === true && packItem.isDeleted === false
      );
      const packItemsToCreate = data.items.filter(
        packItem => packItem.isSaved === false
      );
      const deletedPackItems = packItemsToDelete.map(async (item, index) => {
        return await deletePackItemMutation.mutateAsync(item.itemId);
      });
      const updatedPackItems = packItemsToUpdate.map(async (item, index) => {
        return await updatePackItemMutation.mutateAsync({
          packItemId: item.itemId,
          data: item
        });
      });
      const newPackItems = packItemsToCreate.map(async (item, index) => {
        return await createNewPackItemMutation.mutateAsync({
          packId: updatedPack.id,
          data: item
        });
      });
      await Promise.all(deletedPackItems);
      await Promise.all(updatedPackItems);
      await Promise.all(newPackItems);
      toastSuccess(general.fr.message.packCreated);
      navigate(`/packs`);
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  const onSubmit = async dataArg => {
    const data = { ...dataArg };
    try {
      if (isPackItemsEmpty(data.items)) return;
      const imageFile = verifyFileList(data.image);
      data.image = imageFile;
      data.description = description;
      data.placeId = isAdmin ? data.placeId.id : user?.adminForPlace?.id;
      if (mode === FormMode.UPDATE) {
        updatePack(data);
      } else {
        createPack(data);
      }
    } catch (error) {
      setFormLoading(false);
      toastError(general.fr.message.operationFailed);
    }
  };

  useEffect(() => {
    if (mode === FormMode.UPDATE && params?.id) {
      // get the data for the pack
      const formFields = Object.keys(schema.fields);
      apiService.MakeGetRequest(`packs/${params.id}`).then(response => {
        setDescription(response?.description);
        setFormValue("placeId", response.place);
        setImage(response?.image);
        Object.entries(response).forEach(entry => {
          if (formFields.indexOf(entry[0]) !== -1) {
            if (entry[0] === "items") {
              const formatedItems = entry[1].map(item => ({
                ...item,
                itemId: item.id,
                isSaved: true,
                isDeleted: false
              }));
              setFormValue("items", formatedItems);
            } else {
              setFormValue(entry[0], entry[1]);
            }
          }
        });
      });
    }
  }, []);

  const deletePackItem = async (item, index) => {
    if (item.isSaved === true) {
      updatePackItemField(index, { ...item, isDeleted: true });
    } else {
      removePackItemField(index);
    }
  };

  return (
    <>
      <div className=" mt-4 ">
        <BoxWrapper>
          <section className="p-4">
            <div className="border-bottom border-success">
              <h4>
                {mode === FormMode.UPDATE
                  ? "Mise à jour du pack"
                  : "Ajouter un pack"}
              </h4>
            </div>
            <form className="row form mt-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="col-md-6 mt-4">
                <label htmlFor="title" className="d-block">
                  Titre du Pack
                  <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Titre du Pack"
                  id="title"
                  name="title"
                  {...register("title")}
                />
                {errors.title?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.title.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="image" className="d-block mb-1">
                  Image <span className="text-md text-red">*</span>
                </label>
                {/* {mode === FormMode.UPDATE && getFormValue("image") ? (
                  <ImagePreview url={getFormValue("image")} />
                ) : null} */}
                <FileUploader
                  type="file"
                  id="image"
                  name="image"
                  // className="custom-input"
                  accept="image/png,image/jpeg,image/jpg"
                  required={mode === FormMode.UPDATE ? false : true}
                  {...register("image")}
                  file={image}
                />
                {errors.image?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.image.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="shortDescription" className="d-block">
                  Brève description
                </label>
                <CFormInput
                  type="text"
                  className="custom-input"
                  placeholder="Brève description"
                  id="shortDescription"
                  name="shortDescription"
                  {...register("shortDescription")}
                />
                {errors.shortDescription?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.shortDescription.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="description" className="d-block">
                  Description
                </label>
                <RichTextEditor
                  defaultValue={description}
                  onChange={data => setDescription(data)}
                />
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix <span className="text-md text-red">*</span>
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="price"
                  name="price"
                  {...register("price")}
                />
                {errors.price?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.price.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 mt-4">
                <label htmlFor="price" className="d-block">
                  Prix En Euro
                </label>
                <CFormInput
                  type="number"
                  className="custom-input"
                  placeholder="Prix"
                  id="priceInEuro"
                  name="priceInEuro"
                  {...register("priceInEuro")}
                />
                {errors.priceInEuro?.message && (
                  <div
                    className="text-red-500 text-opacity-50"
                    style={{ color: "red" }}
                  >
                    {errors.priceInEuro.message}
                  </div>
                )}
              </div>
              {isAdmin && (
                <div className="col-md-6 mt-4">
                  <label htmlFor="placeId" className="d-block mb-2">
                    Établissement <span className="text-md text-red">*</span>
                    {selectedPlace?.isVisible === false ? (
                      <span className="text-attention">
                        {" "}
                        (cet établissement est invisible)
                      </span>
                    ) : null}
                  </label>
                  <Controller
                    name="placeId"
                    control={formControl}
                    render={({ field }) => {
                      return (
                        <InfiniteScrollSelect
                          name="placeId"
                          id="placeId"
                          isClearable={false}
                          error={errors?.placeId?.message}
                          getOptionLabel={option => option?.name}
                          getOptionValue={option => option.id}
                          isOptionSelected={(option, selectedValue) => {
                            const isSelected =
                              option?.id === selectedValue?.[0]?.id;
                            return isSelected;
                          }}
                          url={{
                            path: "places"
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors.placeId?.message && (
                    <div
                      className="text-red-500 text-opacity-50"
                      style={{ color: "red" }}
                    >
                      {errors.placeId.message}
                    </div>
                  )}
                </div>
              )}
              <section className="mt-5">
                <h5>Éléments du pack</h5>
                {isPackItemsEmpty(formPackItemsValue) === true ? (
                  <div className="text-red text-opacity-50 mt-5">
                    Le pack doit contenir au moins un élément
                  </div>
                ) : null}
              </section>
              {packItemsFields.map((packItem, index) => {
                return (
                  <div
                    key={packItem?.id}
                    className="mt-4 d-flex justify-items-center align-items-end"
                  >
                    <div
                      className="d-flex justify-items-center align-items-end"
                      style={{
                        opacity: packItem?.isDeleted ? 0.4 : 1,
                        pointerEvents: packItem?.isDeleted ? "none" : "all"
                      }}
                    >
                      <div
                        className="me-3"
                        style={{
                          minWidth: "90px"
                        }}
                      >
                        Élément {index + 1}:{" "}
                      </div>
                      <div className="flex-1">
                        <label htmlFor="title" className="d-block">
                          Titre <span className="text-md text-red">*</span>
                        </label>
                        <CFormInput
                          type="text"
                          className="custom-input"
                          placeholder="Titre"
                          id="title"
                          name="title"
                          {...register(`items.${index}.title`, {
                            required: "Ce champ est obligatoire"
                          })}
                        />
                        {errors.items &&
                          errors.items[index] &&
                          !!errors.items[index].title &&
                          errors.items[index].title.message && (
                            <div style={{ color: "red" }}>
                              {errors.items[index].title.message}
                            </div>
                          )}
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger green-shadow w-20 text-white ms-3"
                        onClick={() => deletePackItem(packItem, index)}
                      >
                        Supprimer
                      </button>
                    </div>
                    {packItem.isDeleted ? (
                      <button
                        type="button"
                        className="btn btn-danger green-shadow w-20 text-white ms-3"
                        onClick={() =>
                          updatePackItemField(index, {
                            ...packItem,
                            isDeleted: false
                          })
                        }
                      >
                        <UndoIcon />
                      </button>
                    ) : null}
                  </div>
                );
              })}
              <section className="mt-5">
                <button
                  type="button"
                  className="btn btn-info green-shadow w-20 text-white"
                  onClick={() => {
                    appendPackItemField({
                      title: "",
                      isSaved: false,
                      isDeleted: false
                    });
                  }}
                >
                  Ajouter un élément de pack
                </button>
              </section>
              <section className="d-flex justify-content-center buttons gap-4 mt-5">
                <Link
                  to="/packs"
                  className="btn btn-danger red-shadow w-20 px-4 py-2 text-white"
                >
                  Annuler
                </Link>
                <button
                  className="btn btn-success green-shadow w-20 text-white"
                  type="submit"
                  disabled={formLoading}
                >
                  {formLoading ? (
                    <div className="text-center">
                      <CSpinner size="sm" />
                    </div>
                  ) : (
                    "Enregistrer"
                  )}
                </button>
              </section>
            </form>
          </section>
        </BoxWrapper>
      </div>
    </>
  );
}
