import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCloseButton,
  CCol,
  CFormCheck,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
  CRow,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from "@coreui/react";
import React, { useEffect, useMemo, useState } from "react";
import { BOOKING_STATUS } from "../../../config/constants";
import { colors } from "../../../config/theme";
import { useMutation, useQueryClient } from "react-query";
import apiService from "../../../service/apiService";
import { useCookie } from "../../../hooks/useCookie";
import { useToast } from "../../../hooks/useToast";
import { general } from "../../../locales/general";
import { useAuth } from "../../../hooks/useAuth";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { BookingStatus, BookingTypes } from "../../../helpers/enums";
import { BookingStatusDisplayValue } from "../../../helpers/enums";
import { format } from "date-fns";
import { HeaderSort } from "../../../components/TableHeaderSort";
import { Link, useSearchParams } from "react-router-dom";
import { useQueryParam } from "../../../hooks/useQueryParam";
import { formatDateString } from "../../../helpers/general";

interface TableBodyProps {
  booking: Booking;
  showDetails: (booking: Booking) => void;
}

function getBookingStatus(booking: Booking) {
  if (booking.status?.label?.toLowerCase() === "confirmed" && booking.isUsed) {
    return BookingStatus.PASSED;
  } else if (booking.status?.label?.toLowerCase() === "canceled") {
    return BookingStatus.CANCELED;
  } else if (booking.status?.label?.toLowerCase() === "confirmed") {
    return BookingStatus.CONFIRMED;
  } else if (booking.status?.label?.toLowerCase() === "refused") {
    return BookingStatus.REFUSED;
  } else if (booking.status?.label?.toLowerCase() === "cash_pre_payment") {
    return BookingStatus.CASH_PRE_PAYMENT;
  }
  return BookingStatus.WAITING;
}

function getBookingStatusDisplayValue(booking: Booking) {
  if (booking.status?.label?.toLowerCase() === "confirmed" && booking.isUsed) {
    return BookingStatusDisplayValue.PASSED;
  } else if (booking.status?.label?.toLowerCase() === "canceled") {
    return BookingStatusDisplayValue.CANCELED;
  } else if (booking.status?.label?.toLowerCase() === "confirmed") {
    return BookingStatusDisplayValue.CONFIRMED;
  } else if (booking.status?.label?.toLowerCase() === "refused") {
    return BookingStatusDisplayValue.REFUSED;
  } else if (booking.status?.label?.toLowerCase() === "cash_pre_payment") {
    return BookingStatusDisplayValue.CASH_PRE_PAYMENT;
  }
  return BookingStatusDisplayValue.WAITING;
}

function TableBodyRowContent({ booking, showDetails }: TableBodyProps) {
  // const [isChecked, setIsChecked] = useState(false);
  const { token } = useCookie("vToken");
  const queryClient = useQueryClient();

  const { toastSuccess, toastError } = useToast();

  const AcceptBooking = useMutation((id: string) => {
    return apiService.MakePutRequest(`booking/${id}/approve`, {}, token);
  });

  const AcceptBookingButton = () => {
    AcceptBooking.mutate(booking.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Réservation acceptée");
        queryClient.invalidateQueries(["getAllBookings"]);
      }
    });
  };

  const RefuseBooking = useMutation((id: string) => {
    return apiService.MakePutRequest(`booking/${id}/refuse`, {}, token);
  });

  const RefuseBookingButton = () => {
    RefuseBooking.mutate(booking.id, {
      onError(error) {
        toastError(general.fr.message.operationFailed);
      },
      onSuccess() {
        toastSuccess("Réservation refusée");
        queryClient.invalidateQueries(["getAllBookings"]);
      }
    });
  };

  const { isAdmin, isPlaceAdmin } = useAuth();

  return (
    <CTableRow
      style={{
        // borderLeft: isChecked ? `3px solid ${colors.primary}` : "",
        width: "100%",
        position: "relative"
      }}
    >
      {isAdmin && (
        <CTableDataCell className="px-3">
          <div className="d-flex align-items-center gap-3">
            <img
              src={
                booking?.place?.logoImage ??
                `https://ui-avatars.com/api/?name=${booking?.place?.name ??
                  booking?.event?.name}`
              }
              alt=""
              width={30}
              height={30}
              style={{ borderRadius: "100%" }}
            />

            <div>
              <p className="fw-bold m-0 text-capitalize text-sm">
                {booking?.place?.name ?? booking?.event?.name}
              </p>
              <p className="m-0 text-sm">
                {booking?.place?.city?.label ?? booking?.event?.address}
              </p>
            </div>
          </div>
        </CTableDataCell>
      )}
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {formatDateString(booking?.createdAt!)} -{" "}
        {format(new Date(booking?.createdAt), "HH:mm")}
      </CTableDataCell>
      {/* <CTableDataCell className="p-3 text-capitalize text-sm">
          {booking?.hour}
        </CTableDataCell> */}
      <CTableDataCell className="p-3 text-sm">
        {booking?.user?.fullName}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-sm">
        {booking?.reference}
      </CTableDataCell>
      {isPlaceAdmin && (
        <>
          <CTableDataCell className="p-3 text-sm">
            {booking?.scanedBy ? booking?.scanedBy.fullName : "-"}
          </CTableDataCell>
          <CTableDataCell className="p-3 text-capitalize text-sm">
            {booking?.scanedAt
              ? new Date(booking?.scanedAt).toDateString()
              : "-"}
          </CTableDataCell>
        </>
      )}
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {getBookingStatusDisplayValue(booking)}
      </CTableDataCell>
      <CTableDataCell className="p-3 text-capitalize text-sm">
        {booking?.wafacashCode !== null
          ? "Wafacash"
          : booking?.paidInApp
          ? "Carte bancaire"
          : booking?.isGuestList
          ? "Invité guestlist"
          : "Sur place"}
      </CTableDataCell>
      {isAdmin ? (
        <CTableDataCell className="p-3 text-sm">
          {booking?.bookingSource}
        </CTableDataCell>
      ) : null}
      <CTableDataCell className="p-3 text-capitalize text-sm d-flex align-items-center gap-3">
        {getBookingStatus(booking) === BookingStatus.WAITING && (
          <>
            <button
              className="btn btn-primary shadow-secondary rounded-pill text-sm text-white"
              onClick={AcceptBookingButton}
              disabled={AcceptBooking.isLoading}
            >
              {AcceptBooking.isLoading ? <CSpinner size="sm" /> : " Accepter"}
            </button>
            <button
              className="btn btn-danger shadow-primary rounded-pill text-sm text-white"
              onClick={RefuseBookingButton}
              disabled={RefuseBooking.isLoading}
            >
              {RefuseBooking.isLoading ? <CSpinner size="sm" /> : " Refuser"}
            </button>
          </>
        )}
        <button
          onClick={() => showDetails(booking)}
          className="btn rounded-pill border py-1 px-3 text-sm"
        >
          Vue
        </button>
      </CTableDataCell>
      {/* <CTableDataCell className="p-3 text-capitalize text-sm d-flex align-items-center gap-3 mt-4"></CTableDataCell> */}
    </CTableRow>
  );
}

interface TableProps {
  bookings: Booking[];
}
export default function EventReservationTable({ bookings }: TableProps) {
  const [searchParams] = useSearchParams();
  const { add, remove } = useQueryParam();
  const { isAdmin, isPlaceAdmin } = useAuth();
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [sort, setSort] = useState({
    field: "createdAt",
    order: "desc"
  });

  const resetSort = () => setSort({ field: "createdAt", order: "desc" });

  useEffect(() => {
    if (searchParams.get("sort")) {
      const [field, order] = searchParams.get("sort")!.split(":");
      setSort({ field, order });
    } else {
      resetSort();
    }
  }, []);

  useEffect(() => {
    add("sort", `${sort.field}:${sort.order}`);
  }, [sort]);

  const total = useMemo(() => {
    const total =
      selectedBooking?.priceToPay ??
      selectedBooking?.servicesBookings?.reduce((total, service) => {
        return total + service.service.startingPrice * service.personsNumber;
      }, 0);

    return total;
  }, [selectedBooking]);

  return (
    <div className="mt-3">
      <CTable responsive>
        <CTableHead>
          <CTableRow>
            {isAdmin && (
              <CTableHeaderCell scope="col" className="text-sm">
                <div className="d-flex align-items-center justify-content-between">
                  Établissement{" "}
                  <div style={{ height: "40px", width: "20px" }}></div>
                </div>
              </CTableHeaderCell>
            )}

            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Date/Heure de creation
                <HeaderSort
                  field="date"
                  sort={sort.order}
                  sortedField={sort}
                  setSort={(field, order) => setSort({ field, order })}
                  clearSort={() => resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>
            {/* <CTableHeaderCell scope="col" className="text-sm">
                Heure
              </CTableHeaderCell> */}
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Utilisateur{" "}
                <div style={{ height: "40px", width: "20px" }}></div>
              </div>
            </CTableHeaderCell>
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Référence
                <HeaderSort
                  field="reference"
                  sort={sort.order}
                  sortedField={sort}
                  setSort={(field, order) => setSort({ field, order })}
                  clearSort={() => resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>

            {isPlaceAdmin && (
              <>
                <CTableHeaderCell scope="col" className="text-sm">
                  <div className="d-flex align-items-center justify-content-between">
                    Scanné par{" "}
                    <div style={{ height: "40px", width: "20px" }}></div>
                  </div>
                </CTableHeaderCell>
                <CTableHeaderCell scope="col" className="text-sm">
                  <div className="d-flex align-items-center justify-content-between">
                    Scanné à{" "}
                    <div style={{ height: "40px", width: "20px" }}></div>
                  </div>
                </CTableHeaderCell>
              </>
            )}
            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Statut <div style={{ height: "40px", width: "20px" }}></div>
              </div>
            </CTableHeaderCell>

            <CTableHeaderCell scope="col" className="text-sm">
              <div className="d-flex align-items-center justify-content-between">
                Méthode de payement{" "}
                <HeaderSort
                  field="paidInApp"
                  sort={sort.order}
                  sortedField={sort}
                  setSort={(field, order) => setSort({ field, order })}
                  clearSort={() => resetSort()}
                  defaultField="createdAt"
                />
              </div>
            </CTableHeaderCell>

            {isAdmin === true ? (
              <CTableHeaderCell scope="col" className="text-sm">
                <div className="d-flex align-items-center justify-content-between">
                  Source de réservation{" "}
                  <HeaderSort
                    field="bookingSource"
                    sort={sort.order}
                    sortedField={sort}
                    setSort={(field, order) => setSort({ field, order })}
                    clearSort={() => resetSort()}
                    defaultField="createdAt"
                  />
                </div>
              </CTableHeaderCell>
            ) : null}

            <CTableHeaderCell
              scope="col"
              className="text-sm"
            ></CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {bookings?.map(booking => (
            <TableBodyRowContent
              key={booking.id}
              booking={booking}
              showDetails={(booking: Booking) => setSelectedBooking(booking)}
            />
          ))}
        </CTableBody>
      </CTable>
      <COffcanvas
        backdrop={true}
        placement="start"
        visible={Object.keys(selectedBooking ?? {}).length > 0}
        onHide={() => setSelectedBooking(null)}
      >
        <div className="border-bottom pb-1 px-3">
          <COffcanvasHeader className="mb-0">
            <COffcanvasTitle>Détails des réservations</COffcanvasTitle>
            <CCloseButton
              className="text-reset"
              onClick={() => setSelectedBooking(null)}
            />
          </COffcanvasHeader>
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-1">
              <UserCircleIcon width={20} />{" "}
              <span className="text-sm">{selectedBooking?.user.fullName}</span>
            </div>
            <div className="mb-1">
              <PhoneIcon width={15} />{" "}
              <span className="text-sm">{selectedBooking?.user.phone}</span>
            </div>
          </div>
          <div className="mb-1">
            <EnvelopeIcon width={15} />{" "}
            <span className="text-sm">{selectedBooking?.user.email}</span>
          </div>
        </div>
        <COffcanvasBody>
          <CRow className="align-items-center justify-content-between">
            <CCol sm="auto">
              {selectedBooking?.place?.name ?? selectedBooking?.event?.name}
            </CCol>
            <CCol sm="auto">
              <button className="btn btn-danger rounded-full py-0 px-2 text-white">
                {selectedBooking &&
                  getBookingStatusDisplayValue(selectedBooking)}
              </button>
            </CCol>
          </CRow>

          <CRow className="align-items-center justify-content-between mt-3">
            <CCol sm="auto" title="Reference">
              <p className="text-sm mb-0">Reference</p>
              {selectedBooking?.reference}
            </CCol>
            {/* <CCol sm="auto" title="date">
                <p className="text-sm mb-0">Date</p>
                {selectedBooking?.createdAt &&
                  format(new Date(selectedBooking?.createdAt!), "dd/MM/yyyy")}
              </CCol>
              <CCol sm="auto" title="heure">
                <p className="text-sm mb-0">Heure</p>
                {selectedBooking?.hour}
              </CCol> */}
          </CRow>

          {selectedBooking?.type === BookingTypes.PLACE_BOOKING ||
          selectedBooking?.type === BookingTypes.SPECIAL_OFFER_BOOKING ? (
            <CRow className="bg-primary-shade p-2 rounded-sm align-items-center justify-content-between mt-3">
              <CCol sm="auto" title="Reference">
                Nombre de personnes: {selectedBooking?.personsNumber}
              </CCol>
            </CRow>
          ) : null}

          <CRow className="align-items-center justify-content-between mt-3">
            <CCol sm="auto" title="Reference">
              Méthode de payement:{" "}
              {selectedBooking?.wafacashCode !== null
                ? "Wafacash"
                : selectedBooking?.paidInApp
                ? "Carte bancaire"
                : selectedBooking?.isGuestList
                ? "Invité guestlist"
                : "Sur place"}
            </CCol>
          </CRow>

          {selectedBooking?.packsBookings!?.length > 0 && (
            <div className="mt-4">
              <p className="mb-2 border-bottom fw-bold">Pack réservé</p>
              <CAccordion flush>
                {selectedBooking?.packsBookings.map(pack => (
                  <CAccordionItem key={pack.id}>
                    <CAccordionHeader className="align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center gap-2 w-75">
                        <img
                          src={pack.pack.image}
                          alt=""
                          width={40}
                          height={40}
                          className="rounded-sm"
                        />

                        <p className="mb-0">
                          {pack.numberOfReservation} x {pack.pack.title}{" "}
                        </p>
                      </div>
                      <p className="mb-0">
                        {/* @ts-ignore */}
                        {pack.pack.price * pack.numberOfReservation}{" "}
                      </p>
                    </CAccordionHeader>
                    <CAccordionBody>
                      <ul>
                        {pack.pack.items.map(packItem => (
                          <li key={packItem.id} className="mb-1">
                            {packItem.title}
                          </li>
                        ))}
                      </ul>
                    </CAccordionBody>
                  </CAccordionItem>
                ))}
              </CAccordion>
            </div>
          )}

          {selectedBooking?.servicesBookings!?.length > 0 && (
            <div className="mt-4 bg-primary-shade p-2 rounded-sm">
              <p className="mb-2 border-bottom fw-bold">Service réservé</p>
              {selectedBooking?.servicesBookings.map(item => (
                <CRow
                  key={item.id}
                  className="mb-2 align-items-center justify-content-between"
                >
                  <CCol sm="auto">
                    {item.personsNumber} x {item.service.label}
                  </CCol>
                  {selectedBooking.priceToPayCurrency === "euro" ? (
                    <CCol sm="auto">
                      {item.personsNumber * item.service.startingPriceEuro} €
                    </CCol>
                  ) : (
                    <CCol sm="auto">
                      {item.personsNumber * item.service.startingPrice} DH
                    </CCol>
                  )}
                </CRow>
              ))}
              {selectedBooking?.appliedPromoCodes &&
                selectedBooking?.appliedPromoCodes?.length > 0 && (
                  <>
                    <CRow className=" mt-3 pt-2 mb-2 align-items-center justify-content-between border-top">
                      <CCol sm="auto">
                        <strong style={{ fontSize: "14px" }}>
                          Sous-Total:
                        </strong>
                      </CCol>
                      {selectedBooking.priceToPayCurrency === "euro" ? (
                        <CCol sm="auto">
                          {" "}
                          {selectedBooking?.servicesBookings?.reduce(
                            (total, service) => {
                              return (
                                total +
                                service.service.startingPriceEuro *
                                  service.personsNumber
                              );
                            },
                            0
                          )}{" "}
                          €
                        </CCol>
                      ) : (
                        <CCol sm="auto">
                          {" "}
                          {selectedBooking?.servicesBookings?.reduce(
                            (total, service) => {
                              return (
                                total +
                                service.service.startingPrice *
                                  service.personsNumber
                              );
                            },
                            0
                          )}{" "}
                          DH
                        </CCol>
                      )}
                    </CRow>
                    {selectedBooking && (
                      <CRow className="mb-2 align-items-center justify-content-between">
                        <CCol sm="auto">
                          <strong style={{ fontSize: "14px" }}>
                            {" "}
                            Remise (
                            {selectedBooking?.appliedPromoCodes[0].discount}
                            %):
                          </strong>
                        </CCol>
                        {selectedBooking.priceToPayCurrency === "euro" ? (
                          <CCol sm="auto">
                            -
                            {selectedBooking?.servicesBookings.reduce(
                              (total, service) =>
                                total +
                                service.service.startingPriceEuro *
                                  service.personsNumber,
                              0
                            ) - selectedBooking?.priceToPay}{" "}
                            €
                          </CCol>
                        ) : (
                          <CCol sm="auto">
                            -
                            {selectedBooking?.servicesBookings.reduce(
                              (total, service) =>
                                total +
                                service.service.startingPrice *
                                  service.personsNumber,
                              0
                            ) - selectedBooking?.priceToPay}{" "}
                            DH
                          </CCol>
                        )}
                      </CRow>
                    )}
                  </>
                )}
              <CRow className="mb-2 align-items-center justify-content-between">
                <CCol sm="auto">
                  {" "}
                  <strong style={{ fontSize: "14px" }}>Total:</strong>{" "}
                </CCol>
                {selectedBooking?.priceToPayCurrency === "euro" ? (
                  <CCol sm="auto">
                    <strong>
                      {selectedBooking?.priceToPay ??
                        selectedBooking?.servicesBookings?.reduce(
                          (total, service) => {
                            return (
                              total +
                              service.service.startingPriceEuro *
                                service.personsNumber
                            );
                          },
                          0
                        )}
                    </strong>{" "}
                    €
                  </CCol>
                ) : (
                  <CCol sm="auto">
                    <strong>
                      {selectedBooking?.priceToPay ??
                        selectedBooking?.servicesBookings?.reduce(
                          (total, service) => {
                            return (
                              total +
                              service.service.startingPrice *
                                service.personsNumber
                            );
                          },
                          0
                        )}
                    </strong>{" "}
                    DH
                  </CCol>
                )}
              </CRow>
            </div>
          )}

          {selectedBooking?.createdAt && (
            <div className="border-top p-1 pt-2 mt-3">
              <p className="mb-0">
                Date de création :{" "}
                {formatDateString(selectedBooking?.createdAt)}
              </p>
              <p className="mb-0">
                Heure de création :{" "}
                {format(new Date(selectedBooking?.createdAt), "HH:mm")}
              </p>
            </div>
          )}

          {selectedBooking?.date && (
            <div className="border-top p-1 pt-2 mt-3">
              <p className="mb-0">
                Date de réservation : {formatDateString(selectedBooking?.date)}
              </p>
              <p className="mb-0">
                Heure de réservation : {selectedBooking?.hour}
              </p>
            </div>
          )}

          {selectedBooking?.scanedBy && (
            <div className="border-top p-1 mt-3">
              <p className="mb-0">
                Scanné par: {selectedBooking?.scanedBy?.fullName}{" "}
              </p>
            </div>
          )}
          {selectedBooking?.scanedAt && (
            <div className="border-bottom p-1">
              <p className="mb-0">
                Scanné à:{" "}
                {format(
                  new Date(selectedBooking?.scanedAt),
                  "dd/MM/yyyy - HH:mm"
                )}
              </p>
            </div>
          )}
        </COffcanvasBody>
      </COffcanvas>
    </div>
  );
}
